import React from "react";
import { ButtonRsrv } from "./ui/ButtonRsrv";
import { Link } from "react-scroll";
import ReactPlayer from "react-player";

const Hero = () => {
  return (
    <div name="demo" className="hero-container fit-container fx-centered">
      <section className="hero-section">
        <div
          className="fit-container fx-centered fx-col"
          style={{ gap: "var(--16)" }}
        >
          <p className="hero-title h0 white-c p-700 p-centered">
            Améliorez votre <span className="green-c">productivité</span>,{" "}
            <br />
            maîtrisez vos <span className="blue-c">risques</span>
          </p>

          <div className="fit-container fx-centered">
            <p className="gray-c hero-text p-centered">
              Simplifiez votre gouvernance, maîtrisez vos risques, et assurez
              votre conformité avec notre solution tout-en-un.
            </p>
          </div>

          <div className="fit-container fx-centered gap-20 hero-contact-buttons">
            <ButtonRsrv text={"Réserver une demo"} styles={"btn-rsrv"} />
            <Link
              smooth
              duration={500}
              to="form"
              className="btn btn-cnct remaind-me"
            >
              Être rappelé
            </Link>
          </div>
        </div>

        <div className="main-bg hero-bg bg-img" />

        <div className="w-90 image-container fx-centered">
          <div className="video-container">
            {/* <video className="video-content" width={"97%"} height={"97%"} controls muted autoPlay>
              <source
                src="https://romy-fs.s3.eu-west-3.amazonaws.com/landing-page-files/romy.mp4"
                type="video/mp4"
              ></source>
            </video> */}
            <ReactPlayer
              url={[
                "https://romy-fs.s3.eu-west-3.amazonaws.com/landing-page-files/romy.mp4",
                "https://romy-fs.s3.eu-west-3.amazonaws.com/landing-page-files/romy-720.mp4",
                "https://romy-fs.s3.eu-west-3.amazonaws.com/landing-page-files/romy-480.mp4",
              ]}
              className="video-content"
              muted
              controls
              playing
              config={{
                file : {
                  forceHLS : true,
                }
              }}
            />
          </div>
          {/* <div className="container bg-img contained-bg image-content"></div> */}
        </div>
      </section>
    </div>
  );
};

export default Hero;
